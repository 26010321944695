import URL_API from "../../apis/urlApi";
import { http } from "../../apis";
import { handleErrorMessage, handleGetTeamId } from "../helpers";
import { InvitationMemberListProps } from "./member.interface";

export async function InvitationMemberList(props: InvitationMemberListProps) {
    try {
        const payload = { 
            profile_id: handleGetTeamId(), 
            ...props 
        }
        const response: any = await http(true).post(URL_API.MEMBER_INVITATION_LIST, payload)
        if (response.code !== '200') throw handleErrorMessage(response?.message, 'error get list invitation')
        return {
            data: response?.data,
            metadata: response?.metadata
        }
    } catch (error) {
        throw error
    }
}