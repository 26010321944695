import PublicRoute from './PublicRoute'
import urlRouter from './url'
import FeedbackForm from '../pages/FeedbackForm/FeedbackForm'
import { lazy } from 'react'
import { DesignSystemMainLayout } from '../designSystem/layouts'
import { Routes, Route } from 'react-router-dom'
import { Login, MainApp, Team } from '../pages'
import {
  DesignSystemButtons,
  DesignSystemColorGuidance,
  DesignSystemTypography,
} from '../designSystem/pages'
import AccessRoute from './AccessRoute'

const Router = () => {
  // code splitting
  const LastLocation = lazy(() => import('../pages/LastLocation/LastLocation'))
  const Report = lazy(() => import('../pages/Report/Report'))
  const Dashboard = lazy(() => import('../pages/Dashboard/Dashboard'))
  const Notification = lazy(() => import('../pages/Notification/Notification'))
  const Invitation = lazy(() => import('../pages/Invitation/Invitation'))

  // attendance
  const AttendanceSetting = lazy(
    () => import('../pages/AttendanceSetting/AttendanceSetting')
  )
  const AttendanceMonitoring = lazy(
    () => import('../pages/AttendanceMonitoring/AttendanceMonitoring')
  )

    // member
    const MemberMaster = lazy(
        () => import('../pages/MemberMaster/MemberMaster')
    )
    const MemberDetail = lazy(() => import('../pages/MemberDetail/MemberDetail'))
    const MemberDetailItem = lazy(() => import('../pages/MemberDetail/MemberDetailPerson/MemberDetailPerson'))
    const MemberDetailEdit = lazy(() => import('../pages/MemberDetail/MemberDetailPerson/components/DetailEdit/DetailEdit'))
    const MemberGroup = lazy(() => import('../pages/MemberGroup/MemberGroup'))
    const MemberList = lazy(() => import('../pages/MemberList/MemberList'))
    const MemberSetting = lazy(
        () => import('../pages/MemberSetting/MemberSetting')
    )
    const FeedbackMember = lazy(
        () => import('../pages/FeedbackMember/FeedbackMember')
    )
    const InviteList = lazy(
        () => import('../pages/MemberMaster/components/InviteList/InviteList')
    )

  // task
  const MasterTask = lazy(() => import('../pages/MasterTask/MasterTask'))
  const TaskType = lazy(() => import('../pages/TaskType/TaskType'))
  const TaskSetting = lazy(() => import('../pages/TaskSetting/TaskSetting'))

  // configuration
  const Lookup = lazy(() => import('../pages/Lookup/Lookup'))

  // item
  const DashboardItem = lazy(
    () => import('../pages/Item/DashboardItem/DashboardItem')
  )
  const AddItem = lazy(() => import('../pages/AddItem/AddItem'))
  const ItemList = lazy(() => import('../pages/ItemList/ItemList'))

  // cash advanced
  const CashAdvancedMaster = lazy(
    () => import('../pages/CashAdvancedMaster/CashAdvancedMaster')
  )
  const CashAdvancedTransaction = lazy(
    () => import('../pages/CashAdvancedTransaction/CashAdvancedTransaction')
  )

  // Customer
  const CustomerSetting = lazy(
    () => import('../pages/CustomerSetting/CustomerSetting')
  )
  const CustomerCreate = lazy(
    () => import('../pages/CustomerCreate/CustomerCreate')
  )
  const MasterCustomer = lazy(
    () => import('../pages/MasterCustomer/MasterCustomer')
  )
  const CustomerAnalisis = lazy(
    () => import('../pages/CustomerAnalisis/CustomerAnalisis')
  )

  //Task
  const TaskMonitoring = lazy(
    () => import('../pages/TaskMonitoring/TaskMonitoring')
  )
  const TaskAnalytics = lazy(
    () => import('../pages/TaskAnalytics/TaskAnalytics')
  )
  const TaskTabs = lazy(() => import('../pages/TaskMonitoring/actions/Tabs'))
  const CreateTask = lazy(() => import('../pages/CreateTask/CreateTask'))
  const EditTask = lazy(() => import('../pages/EditTask/EditTask'))

  // builder
  const DashboardBuilder = lazy(
    () => import('../pages/DashboardBuilder/DashboardBuilder')
  )
  const BuilderReport = lazy(
    () => import('../pages/BuilderReport/BuilderReport')
  )

  // MEMBER JOURNEY
  const MemberJourney = lazy(
    () => import('../pages/MemberJourney/MemberJourney')
  )
  const MemberJourneyDetail = lazy(
    () => import('../pages/MemberJourneyDetail/MemberJourneyDetail')
  )

  // const FeedbackForm = lazy(() => import('../pages/FeedbackForm/FeedbackForm'))

  // PERSONAL DASHBAORD
  const PersonalDashboard = lazy(
    () => import('../pages/PersonalDashboard/PersonalDashboard')
  )
  const PersonalDashboardPco = lazy(
    () => import('../pages/PersonalDashboardPco/PersonalDashboardPco')
  )
  const PersonalDashboardMaximus = lazy(
    () => import('../pages/PersonalDashboardMaximus/PersonalDashboardMaximus')
  )

  // LEAVE
  const LeaveApproval = lazy(
    () => import('../pages/LeaveApproval/LeaveApproval')
  )
  const LeaveSetting = lazy(() => import('../pages/LeaveSetting/LeaveSetting'))

  // TEAM CONFIGURATION
  const TeamConfiguration = lazy(
    () => import('../pages/TeamConfiguration/TeamConfiguration')
  )

  /**
   * INVOICE
   */
  const InvoiceDashboard = lazy(
    () => import('../pages/InvoiceDashboard/InvoiceDashboard')
  )
  const InvoiceMaster = lazy(
    () => import('../pages/InvoiceMaster/InvoiceMaster')
  )
  const InvoiceCreate = lazy(
    () => import('../pages/InvoiceCreate/InvoiceCreate')
  )
  const InvoiceDetail = lazy(
    () => import('../pages/InvoiceDetail/InvoiceDetail')
  )
  const InvoiceSetting = lazy(
    () => import('../pages/InvoiceSetting/InvoiceSetting')
  )

  /**
   * Management Ticket
   */
  const ManagementTicket = lazy(
    () => import('../pages/ManagementTicket/ManagementTicket')
  )
  const TicketDetail = lazy(() => import('../pages/TicketDetail/TicketDetail'))
  const ManagementTicketTab = lazy(
    () => import('../pages/ManagementTicket/Action/TicketTabs')
  )
  const SettingTicket = lazy(
    () => import('../pages/TicketSetting/TicketSetting')
  )

  /**
   * Leads
   */
  const LeadsMaster = lazy(
    () => import('../pages/Leads/LeadsMaster/LeadsMaster')
  )
  const LeadsDashboard = lazy(
    () => import('../pages/Leads/LeadsDashboard/LeadsDashboard')
  )
  const LeadsActivity = lazy(
    () => import('../pages/Leads/LeadsActivity/LeadsActivity')
  )
  const LeadsActivityResult = lazy(
    () =>
      import(
        '../pages/Leads/LeadDetail/components/TabSummary/DetailActivity/DetailActivityResult'
      )
  )
  const LeadsSetting = lazy(
    () => import('../pages/Leads/LeadsSetting/LeadsSetting')
  )
  const LeadsCreate = lazy(
    () => import('../pages/Leads/LeadsCreate/LeadsCreate')
  )
  const LeadsImport = lazy(
    () => import('../pages/Leads/LeadsImport/LeadImport')
  )
  const LeadsDetail = lazy(() => import('../pages/Leads/LeadDetail/LeadDetail'))
  const LeadsEdit = lazy(() => import('../pages/Leads/LeadEdit/LeadEdit'))

  /**
   * Bulletin
   */
  const Bulletin = lazy(() => import('../pages/Bulletin/Bulletin'))
  const FormBulletin = lazy(
    () => import('../pages/Bulletin/components/FormBulletin/FormBulletin')
  )

  /**
   * Researches
   */
  const MapPolygon = lazy(
    () => import('../pages/Researches/MapPolygon/MapPolygon')
  )
  const PdfEditor = lazy(
    () => import('../pages/Researches/PdfEditor/PdfEditor')
  )
  const CalendarView = lazy(
    () => import('../pages/Researches/CalendarView/CalendarView')
  )

  /**
   * Inventory
   */
  const InventoryAddItem = lazy(
    () => import('../pages/Inventory/AddItem/AddItem')
  )
  const InventoryMasterItem = lazy(
    () => import('../pages/Inventory/MasterItem/MasterItem')
  )
  const InventoryMasterCategory = lazy(
    () => import('../pages/Inventory/MasterCategory/MasterCategory')
  )
  const InventoryStockHistory = lazy(
    () => import('../pages/Inventory/StockHistory/StockHistory')
  )
  const InventoryUsage = lazy(
    () => import('../pages/Inventory/InventoryUsage/InventoryUsage')
  )
  const InventoryDashboard = lazy(
    () => import('../pages/Inventory/DashboardInventory/DashboardInventory')
  )
  const InventoryInventory = lazy(
    () => import('../pages/Inventory/Inventory/Inventory')
  )

  /**
   * Settlement
   */
  const SettlementSettlement = lazy(
    () => import('../pages/Settlement/Settlement/Settlement')
  )

  /**
   * POS
   */
  const PosDashboard = lazy(() => import('../pages/Pos/Dashboard/Dashboard'))
  const PosMasterMenu = lazy(() => import('../pages/Pos/MasterMenu/MasterMenu'))
  const PosCommission = lazy(() => import('../pages/Pos/Commission/Commission'))
  const PosTransaction = lazy(
    () => import('../pages/Pos/Transaction/Transaction')
  )

  /**
   * Standalone
   * Master Partner
   */
  const MasterOutlet = lazy(() => import('../pages/MasterOutlet/MasterOutlet'))

  /**
   * Standalone
   * Master Outlet
   */
  const MasterStore = lazy(() => import('../pages/MasterStore/MasterStore'))

  /**
   * Standalone
   * Master Product
   */
  const MasterProduct = lazy(
    () => import('../pages/MasterProduct/MasterProduct')
  )

  /**
   * Standalone
   * Master Ingredient
   */
  const MasterIngredient = lazy(
    () => import('../pages/MasterIngredient/MasterIngredient')
  )

  /**
   * Standalone
   * Master Recipe
   */
  const MasterRecipe = lazy(() => import('../pages/MasterRecipe/MasterRecipe'))

  /**
   * Standalone
   * Task Calendar View
   */
  const TaskCalendarView = lazy(
    () => import('../pages/TaskCalendarView/TaskCalendarView')
  )

  /**
   * Cost Perdin
   */
  const CostPerdin = lazy(() => import('../pages/CostPerdin/CostPerdin'))
  const CreateCostPerdin = lazy(
    () => import('../pages/CreateCostPerdin/CreateCostPerdin')
  )
  const CostPerdinDetail = lazy(
    () => import('../pages/CostPerdinDetail/CostPerdinDetail')
  )

  /**
   * Standalone
   * Plan Visit
   */
  // const PlanVisit = lazy(
  //   () => import('../pages/PlanVisit/PlanVisit')
  // )

  /**
   * Sales Order
   */
  const SalesOrderDashboard = lazy(() => import('../pages/SalesOrderDashboard/SalesOrderDashboard'))
  const SalesOrderProduct = lazy(() => import('../pages/SalesOrderProduct/SalesOrderProduct'))
  const SalesOrderTransaction = lazy(() => import('../pages/SalesOrderTransaction/Transaction'))
  const SalesOrderSetting = lazy(() => import('../pages/SalesOrderSetting/Settings'))
  const SalesOrderCreateProduct = lazy(() => import('../pages/SalesOrderCreateProduct/SalesOrderCreateProduct'))
  const SalesOrderEditProduct = lazy(() => import('../pages/SalesOrderEditProduct/SalesOrderEditProduct'))
  const SalesOrderBulkUpload = lazy(() => import('../pages/SalesOrderBulkUpload/SalesOrderBulkUpload'))
  const SalesOrderBulkUpdate = lazy(() => import('../pages/SalesOrderBulkUpdate/SalesOrderBulkUpdate'))

  const isModuleItemEnable = false
  const isLeadsEnable = true
  const isPosEnable = true
  const isInvoiceEnable = false
  const isInventoryEnable = true
  const isSettlementEnable = true
  const isMasterOutletEnable = true
  const isMasterProductEnable = true
  const isMasterIngredientEnable = true
  const isMasterRecipeEnable = true
  const isTaskCalendarViewEnable = true
  const isMasterStoreEnable = true
  const isCostPerdinEnable = true
  const isCreateCostPerdinEnable = true
  const isDetailCostPerdinEnable = true

  return (
    <Routes>
      <Route path={urlRouter.home} element={<MainApp />}>
        <Route element={<AccessRoute access='1009' />}>
          <Route path={urlRouter.dashboard} element={<Dashboard />} />
        </Route>
        <Route element={<AccessRoute access='4' />}>
          <Route path={urlRouter.lastLocation} element={<LastLocation />} />
        </Route>
        <Route element={<AccessRoute access='1001' />}>
          <Route path={urlRouter.report} element={<Report />} />
        </Route>
        <Route path={urlRouter.team} element={<Team />} />
        <Route path={urlRouter.notification} element={<Notification />} />
        <Route path={urlRouter.invitation} element={<Invitation />} />
        {/* customer */}
        <Route element={<AccessRoute access='1008' />}>
          <Route path={urlRouter.customerSetting} element={<CustomerSetting />} />
        </Route>
        <Route element={<AccessRoute access='1008' />}>
          <Route path={urlRouter.CUSTOMER_MASTER} element={<MasterCustomer />} />
        </Route>
        <Route element={<AccessRoute access='1008' />}>
          <Route
            path={urlRouter.customerAnalisis}
            element={<CustomerAnalisis />}
          />
        </Route>
        <Route path={urlRouter.CUSTOMER_CREATE} element={<CustomerCreate />} />
        <Route path={urlRouter.CUSTOMER_EDIT} element={<CustomerCreate />} />
        {/* task */}
        <Route element={<AccessRoute access='1013' />}>
          <Route path={urlRouter.taskMaster} element={<MasterTask />} />
        </Route>
        <Route path={urlRouter.createTask} element={<CreateTask />} />
        <Route element={<AccessRoute access='1003' />}>
          <Route path={urlRouter.taskType} element={<TaskType />} />
        </Route>
        <Route element={<AccessRoute access='1013' />}>
          <Route path={urlRouter.taskAnalytics} element={<TaskAnalytics />} />
          <Route path={urlRouter.taskSetting} element={<TaskSetting />} />
        </Route>
        <Route element={<AccessRoute access='1020' />}>
          <Route path={urlRouter.taskMonitoring} element={<TaskMonitoring />} />
          <Route
            path={urlRouter.taskMonitoringDetailResult}
            element={<TaskTabs />}
          />
        </Route>
        <Route path={urlRouter.editTask} element={<EditTask />} />
        {/* item */}
        {isModuleItemEnable ? (
          <>
            <Route element={<AccessRoute access='1013' />}>
              <Route path={urlRouter.itemDashboard} element={<DashboardItem />} />
              <Route path={urlRouter.itemList} element={<ItemList />} />
              <Route path={urlRouter.itemCreate} element={<AddItem />} />
            </Route>
          </>
        ) : null}
        {/* cash advanced */}
        <Route element={<AccessRoute access='1014' />}>
          <Route path={urlRouter.cashAdvance} element={<CashAdvancedMaster />} />
        </Route>
        <Route element={<AccessRoute access='1015' />}>
          <Route
            path={urlRouter.cashAdvanceTransaction}
            element={<CashAdvancedTransaction />}
          />
        </Route>
        {/* member */}
        <Route element={<AccessRoute access='1017' />}>
          <Route path={urlRouter.memberMaster} element={<MemberMaster />} />
          <Route path={urlRouter.memberDetail} element={<MemberDetail />} />
          <Route path={urlRouter.memberDetailItem} element={<MemberDetailItem />} />
          <Route path={urlRouter.memberDetailEdit} element={<MemberDetailEdit />} />
          <Route path={urlRouter.memberGroup} element={<MemberGroup />} />
          <Route path={urlRouter.memberPrivilage} element={<MemberList />} />
          <Route path={urlRouter.memberInviteList} element={<InviteList />} />
          <Route path={urlRouter.memberGroup} element={<MemberGroup />} />
        </Route>
        <Route element={<AccessRoute access='1023' />}>
          <Route path={urlRouter.memberSetting} element={<MemberSetting />} />
        </Route>
        <Route element={<AccessRoute access='1024' />}>
          <Route path={urlRouter.memberFeedback} element={<FeedbackMember />} />
        </Route>
        {/* attendance */}
        <Route element={<AccessRoute access='1021' />}>
          <Route
            path={urlRouter.attendaceMonitoring}
            element={<AttendanceMonitoring />}
          />
        </Route>
        <Route element={<AccessRoute access='1022' />}>
          <Route
            path={urlRouter.attendaceSetting}
            element={<AttendanceSetting />}
          />
        </Route>
        {/* configuration */}
        <Route element={<AccessRoute access='1012' />}>
          <Route path={urlRouter.lookup} element={<Lookup />} />
        </Route>
        {/* builder */}
        <Route element={<AccessRoute access='1016' />}>
          <Route path={urlRouter.builderReport} element={<BuilderReport />} />
          <Route
            path={urlRouter.builderDashboard}
            element={<DashboardBuilder />}
          />
          <Route path={urlRouter.builderReport} element={<></>} />
        </Route>
        {/* MEMBER JOURNEY */}
        <Route element={<AccessRoute access='3' />}>
          <Route path={urlRouter.memberJourney} element={<MemberJourney />} />
          <Route
            path={urlRouter.memberJourneyDetail}
            element={<MemberJourneyDetail />}
          />
        </Route>
        {/* PERSONAL DASHBOARD */}
        <Route
          path={urlRouter.PERSONAL_DASHBOARD}
          element={<PersonalDashboard />}
        />
        <Route
          path={urlRouter.PERSONAL_DASHBOARD_PCO}
          element={<PersonalDashboardPco />}
        />
        <Route element={<AccessRoute access='1009' />}>
          <Route
            path={urlRouter.PERSONAL_DASHBOARD_MAXIMUS}
            element={<PersonalDashboardMaximus />}
          />
        </Route>
        {/* LEAVE */}
        <Route element={<AccessRoute access='1025' />}>
          <Route path={urlRouter.LEAVE_APPROVAL} element={<LeaveApproval />} />
        </Route>
        <Route element={<AccessRoute access='1026' />}>
          <Route path={urlRouter.LEAVE_SETTING} element={<LeaveSetting />} />
        </Route>
        /** TEAM CONFIGURATION */
        <Route element={<AccessRoute access='1009' />}>
          <Route
            path={urlRouter.TEAM_CONFIGURATION}
            element={<TeamConfiguration />}
          />
        </Route>
        /** INVOICE */
        <Route element={<AccessRoute access='1013' />}>
          <Route
            path={urlRouter.attendaceSetting}
            element={<AttendanceSetting />}
          />
        </Route>
        {isInvoiceEnable ? (
          <Route element={<AccessRoute access='1013' />}>
            <Route
              path={urlRouter.INVOICE_DASHBOARD}
              element={<InvoiceDashboard />}
            />
            <Route
              path={urlRouter.INVOICE_MASTER}
              element={<InvoiceMaster />}
            />
            <Route
              path={urlRouter.INVOICE_CREATE}
              element={<InvoiceCreate />}
            />
            <Route
              path={urlRouter.INVOICE_DETAIL}
              element={<InvoiceDetail />}
            />
            <Route
              path={urlRouter.INVOICE_SETTING}
              element={<InvoiceSetting />}
            />
          </Route>
        ) : null}
        /** * Management Ticket */
        <Route element={<AccessRoute access='1027' />}>
          <Route
            path={urlRouter.MANAGEMENT_TICKET}
            element={<ManagementTicket />}
          />
          <Route
            path={urlRouter.MANAGEMENT_TICKET_DETAIL}
            element={<TicketDetail />}
          />
          <Route
            path={urlRouter.MANAGEMENT_TICKET_DETAIL}
            element={<ManagementTicketTab />}
          />
          <Route
            path={urlRouter.MANAGEMENT_TICKET_SETTING}
            element={<SettingTicket />}
          />
        </Route>
        /** * Leads */
        {isLeadsEnable ? (
          <>
            <Route element={<AccessRoute access='1032' />}>
              <Route path={urlRouter.LEADS_MASTER} element={<LeadsMaster />} />
              <Route
                path={urlRouter.LEADS_DASHBOARD}
                element={<LeadsDashboard />}
              />
              <Route
                path={urlRouter.LEADS_ACTIVITY}
                element={<LeadsActivity />}
              />
              <Route path={urlRouter.LEADS_SETTING} element={<LeadsSetting />} />
              <Route path={urlRouter.LEADS_CREATE} element={<LeadsCreate />} />
              <Route path={urlRouter.LEADS_IMPORT} element={<LeadsImport />} />
              <Route path={urlRouter.LEADS_DETAIL} element={<LeadsDetail />} />
              <Route path={urlRouter.LEADS_EDIT} element={<LeadsEdit />} />
              <Route
                path={urlRouter.LEADS_DETAIL_RESULT}
                element={<LeadsActivityResult />}
              />
            </Route>
          </>
        ) : null}
        /** * Bulletin */
        <Route element={<AccessRoute access='1001' />}>
          <Route path={urlRouter.BULLETIN} element={<Bulletin />} />
          <Route path={urlRouter.BULLETIN_CREATE} element={<FormBulletin />} />
          <Route path={urlRouter.BULLETIN_EDIT} element={<FormBulletin />} />
        </Route>
        /** * Inventory */
        {isInventoryEnable ? (
          <>
            <Route element={<AccessRoute access='1013' />}>
              <Route
                path={urlRouter.INVENTORY_DASHBOARD}
                element={<InventoryDashboard />}
              />
              <Route
                path={urlRouter.INVENTORY_MASTER_ITEM}
                element={<InventoryMasterItem />}
              />
              <Route
                path={urlRouter.INVENTORY_MASTER_CATEGORY}
                element={<InventoryMasterCategory />}
              />
              <Route
                path={urlRouter.INVENTORY_USAGE}
                element={<InventoryUsage />}
              />
              <Route
                path={urlRouter.INVENTORY_STOCK_HISTORY}
                element={<InventoryStockHistory />}
              />
              <Route
                path={urlRouter.INVENTORY_ADD_ITEM}
                element={<InventoryAddItem />}
              />
              <Route
                path={urlRouter.INVENTORY_INVENTORY}
                element={<InventoryInventory />}
              />
            </Route>
          </>
        ) : null}
        /** * Settlement */
        {isSettlementEnable ? (
          <Route element={<AccessRoute access='1031' />}>
            <Route
              path={urlRouter.SETTLEMENT_SETTLEMENT}
              element={<SettlementSettlement />}
            />
          </Route>
        ) : null}
        /** * POS */
        {isPosEnable ? (
          <>
            <Route element={<AccessRoute access='1031' />}>
              <Route path={urlRouter.POS_DASHBOARD} element={<PosDashboard />} />
              <Route
                path={urlRouter.POS_MASTER_MENU}
                element={<PosMasterMenu />}
              />
              <Route
                path={urlRouter.POS_COMMISSION}
                element={<PosCommission />}
              />
              <Route
                path={urlRouter.POS_TRANSACTION}
                element={<PosTransaction />}
              />
            </Route>
          </>
        ) : null}
        /** * Master Outlet */
        {isMasterOutletEnable ? (
          <Route element={<AccessRoute access='1028' />}>
            <Route path={urlRouter.MASTER_OUTLET} element={<MasterOutlet />} />
          </Route>
        ) : null}
        /** * Master Store */
        {isMasterStoreEnable ? (
          <Route element={<AccessRoute access='1029' />}>
            <Route path={urlRouter.MASTER_STORE} element={<MasterStore />} />
          </Route>
        ) : null}
        /** * Master Product */
        {isMasterProductEnable ? (
          <Route element={<AccessRoute access='1030' />}>
            <Route path={urlRouter.MASTER_PRODUCT} element={<MasterProduct />} />
          </Route>
        ) : null}
        /** * Master Ingredient */
        {isMasterIngredientEnable ? (
          <Route
            path={urlRouter.MASTER_INGREDIENT}
            element={<MasterIngredient />}
          />
        ) : null}
        /** * Master Ingredient */
        {isMasterRecipeEnable ? (
          <Route path={urlRouter.MASTER_RECIPE} element={<MasterRecipe />} />
        ) : null}
        /** * Task Calendar View */
        {isTaskCalendarViewEnable ? (
          <Route
            path={urlRouter.TASK_CALENDAR_VIEW}
            element={<TaskCalendarView />}
          />
        ) : null}
        /** * Researches */
        <Route path={urlRouter.RESEARCH_MAP_POLYGON} element={<MapPolygon />} />
        <Route path={urlRouter.RESEARCH_PDF_EDITOR} element={<PdfEditor />} />
        <Route
          path={urlRouter.RESEARCH_CALENDAR_VIEW}
          element={<CalendarView />}
        />
        <Route element={<AccessRoute access='1033' />}>
          {isCostPerdinEnable ? (
            <Route path={urlRouter.COST_PERDIN} element={<CostPerdin />} />
          ) : null}
          {isCreateCostPerdinEnable ? (
            <Route
              path={urlRouter.CREATE_COST_PERDIN}
              element={<CreateCostPerdin />}
            />
          ) : null}
          {isDetailCostPerdinEnable ? (
            <Route
              path={urlRouter.DETAIL_COST_PERDIN}
              element={<CostPerdinDetail />}
            />
          ) : null}
        </Route>

        <Route element={<AccessRoute access='1035' />}>
          {/* <Route path={urlRouter.SALES_ORDER_DASHBOARD} element={<SalesOrderDashboard />} /> */}
          <Route path={urlRouter.SALES_ORDER_PRODUCT} element={<SalesOrderProduct />} />
          <Route path={urlRouter.SALES_ORDER_TRANSACTION} element={<SalesOrderTransaction />} />
          <Route path={urlRouter.SALES_ORDER_SETTING} element={<SalesOrderSetting />} />
          <Route path={urlRouter.SALES_ORDER_CREATE_PRODUCT} element={<SalesOrderCreateProduct />} />
          <Route path={urlRouter.SALES_ORDER_UPDATE_PRODUCT} element={<SalesOrderCreateProduct />} />
          <Route path={urlRouter.SALES_ORDER_BULK_UPLOAD_PRODUCT} element={<SalesOrderBulkUpload />} />
          <Route path={urlRouter.SALES_ORDER_BULK_UPDATE_PRODUCT} element={<SalesOrderBulkUpdate />} />
        </Route>
      </Route>

      <Route path={urlRouter.login} element={<PublicRoute />}>
        <Route path={urlRouter.login} element={<Login />} />
      </Route>

      <Route
        path={urlRouter.DESIGN_SYSTEM}
        element={<DesignSystemMainLayout />}
      >
        <Route
          path={urlRouter.DESIGN_SYSTEM_TYPOGRAPHY}
          element={<DesignSystemTypography />}
        />
        <Route
          path={urlRouter.DESIGN_SYSTEM_COLOR_GUIDANCE}
          element={<DesignSystemColorGuidance />}
        />
        <Route
          path={urlRouter.DESIGN_SYSTEM_BUTTONS}
          element={<DesignSystemButtons />}
        />
      </Route>

      <Route path={urlRouter.feedbackForm} element={<FeedbackForm />} />
    </Routes>
  )
}

export default Router
