import React from 'react'
import { 
    Table, 
    TableContainer, 
    Tbody, 
    Td, 
    Th, 
    Thead, 
    Tr 
} from '@chakra-ui/react'
import { useTable } from 'react-table'
import JTypography from '../JTypography/JTypography'

interface JTableProps {
    data: any[]
    columns: any[]
    hooks?: any
    max_width?: any
    max_height?: any
    onClick?: any
    sx?: any
}

const JTable = (props: JTableProps) => {
    const { columns, data } = props

    const tableInstance: any = useTable(
        {
            columns,
            data,
        },
        props.hooks,
    )

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = tableInstance;

    return (
        <TableContainer 
            {...getTableProps()} 
            overflowX={'auto'} 
            overflowY={'auto'} 
            maxWidth={props.max_width} 
            maxHeight={props.max_height} 
            cursor={'default'}
            sx={props.sx}
        >
            <Table 
                variant={'unstyled'} 
                borderTopLeftRadius={'16px'} 
                borderTopRightRadius={'16px'} 
                overflow={'hidden'}
            >
                <Thead>
                    {headerGroups.map((headerGroup: any, index: number) => {
                        return (
                            <Tr 
                                key={index} 
                                backgroundColor={'surfacePlus4'}
                                {...headerGroup.getHeaderGroupProps()} 
                            >
                                {headerGroup.headers.map((column: any, index: any) => {
                                    return (
                                        <Th
                                            key={index}
                                            {...column.getHeaderProps()}
                                            padding={'24px 16px'}
                                        >
                                            {
                                                column?.CustomHeader
                                                    ?   column.render("CustomHeader")
                                                    :   <JTypography size={'title-small'} textTransform={'capitalize'}>
                                                            {column.render("Header")}
                                                        </JTypography>
                                            }
                                        </Th>
                                    )
                                })}
                            </Tr>
                        )
                    })}
                </Thead>
                <Tbody {...getTableBodyProps()}>
                    {rows.map((row: any, rowIndex: number) => {
                        prepareRow(row);
                        return (
                            <Tr
                                key={rowIndex}
                                backgroundColor={'surfacePlus1'}
                                onClick={(event) => {
                                    if (!props.onClick) return
                                    props.onClick(row.original, event)
                                }}
                                borderBottomWidth={'1px'}
                                borderBottomColor={'surfacePlus3'}
                                {...row.getRowProps()}
                            >
                                {row.cells.map((cell: any, cellIndex: number) => {
                                    return (
                                        <Td key={cellIndex} {...cell.getCellProps()} padding={'12px 16px'}>
                                            <JTypography size={'body-medium'}>
                                                {cell.render("Cell")}
                                            </JTypography>
                                        </Td>
                                    )
                                })}
                            </Tr>
                        )
                    })}
                </Tbody>
            </Table>
        </TableContainer>
    )
}

export default JTable