import {
    useRef,
    useState,
    useEffect,
} from 'react'
import style from './header.module.css'
import { motion } from 'framer-motion'
import { setDarkColor, setLightColor } from '../../theme'
import { lOGO_JARIVIS, LOGO_JARIVIS_ONLY } from '../../assets'
import { useLocation, useNavigate } from 'react-router-dom'
import { urlRouter } from '../../router'
import { getChatsApi, readChatApi } from '../../apis/taskDiscussionApi'
import ChatListDrawer from './components/ChatListDrawer'
import MessageListDrawer from './components/MessageListDrawer'
import { onMessage } from 'firebase/messaging'
import { firebaseMessaging } from '../../firebase'
import JToast from '../JToast/JToast'
import NotificationButton from './components/NotificationButton'
import ChatButton from './components/ChatButton'
import UserButton from './components/UserButton'
import {
    useMutation,
    useQuery,
    useQueryClient
} from 'react-query'
import {
    Flex,
    IconButton,
    Image,
    useColorMode,
    useDisclosure,
    useToast
} from '@chakra-ui/react'
import JGoogleIcon from '../JGoogleIcon/JGoogleIcon'
import { useDispatch, useSelector } from 'react-redux'
import { teamSliceV2 } from '../../redux'

const Header = () => {
    const { colorMode, toggleColorMode } = useColorMode()
    const navigate = useNavigate()
    const location = useLocation()
    const isBlacklistPage = location.pathname === urlRouter.team || location.pathname === urlRouter.invitation
    const queryClient = useQueryClient()
    const toast = useToast()
    const [chatId, setChatId] = useState<any>(null)
    const [taskId, setTaskId] = useState<any>(null)

    const [themeLogo, setThemeLogo] = useState<any>(null)

    const dispatch = useDispatch<any>()
    const teamV2: any = useSelector((state: any) => state?.teamV2)

    const { isOpen: isOpenChatList, onOpen: onOpenChatList, onClose: onCloseChatList } = useDisclosure()
    const btnRefChatList: any = useRef()

    const { isOpen: isOpenMessageList, onOpen: onOpenMessageList, onClose: onCloseMessageList } = useDisclosure()
    const btnRefMessageList: any = useRef()
    const chats = useQuery('header-get-chats', handleGetChats)
    const readChat = useMutation(handleReadChat, {
        onSuccess: () => {
            queryClient.invalidateQueries('header-get-chats')
        }
    })

    async function handleGetChats() {
        return await getChatsApi({})
    }

    async function handleReadChat(chatId: any) {
        return await readChatApi({ chat_id: chatId })
    }

    function toggleTheme() {
        if (colorMode === 'light') {
            setDarkColor();
        } else {
            setLightColor();
        };
        if (location.pathname === '/' || location.pathname.includes('detail-task') || location.pathname === '/personal-dashboard') {
            window.location.reload();
        }
        toggleColorMode();
    };

    function toggleMobileSidebar() {
        const sidebarMobile = document.documentElement.style.getPropertyValue('--sidebarMobile');
        if (sidebarMobile === '0px') {
            document.documentElement.style.setProperty('--sidebarMobile', '-100%');
        } else {
            document.documentElement.style.setProperty('--sidebarMobile', '0px');
        }
    }

    function handleClickChatItem(chatId: any, taskId: any) {
        setChatId(chatId)
        setTaskId(taskId)
        onOpenMessageList()
        readChat.mutate(chatId)
    }

    function handleExitChatRoom() {
        setChatId(null)
        setTaskId(null)
        onCloseMessageList()
    }

    async function handleIncomingChatNotification() {
        chatId && queryClient.invalidateQueries(`header-message-list-drawer-get-messages-${chatId}`)
        chatId && readChat.mutate(chatId)
        !chatId && queryClient.invalidateQueries('header-get-chats')
    }

    useEffect(() => {
        dispatch(teamSliceV2?.getTeamDetailAction())
        new Promise((resolve) => {
            onMessage(firebaseMessaging, async (payload) => {
                console.log({FCM_GLOBAL: payload})
                switch (payload?.data?.notif_type) {
                    case 'Task Discussion':
                        await handleIncomingChatNotification()
                        return (
                            toast({
                                position: 'top-right',
                                render: () => (
                                    <JToast
                                        type={'chat'}
                                        message={`${payload?.data?.title}: ${payload?.data?.body.substring(0, 10)}`}
                                    />
                                ),
                                duration: 3000,
                                isClosable: true,
                            })
                        )
                    case 'Leave Request Created':
                        await handleIncomingChatNotification()
                        return (
                            toast({
                                position: 'top-right',
                                render: () => (
                                    <JToast
                                        type={'chat'}
                                        message={`${payload?.data?.title}: ${payload?.data?.body.substring(0, 10)}`}
                                    />
                                ),
                                duration: 3000,
                                isClosable: true,
                            })
                        )
                    case 'Leave Request Edited':
                        return (
                            toast({
                                position: 'top-right',
                                render: () => (
                                    <JToast
                                        type={'chat'}
                                        message={`${payload?.data?.title}: ${payload?.data?.body.substring(0, 10)}`}
                                    />
                                ),
                                duration: 3000,
                                isClosable: true,
                            })
                        )
                    case 'Leave Approval Accepted':
                        return (
                            toast({
                                position: 'top-right',
                                render: () => (
                                    <JToast
                                        type={'chat'}
                                        message={`${payload?.data?.title}: ${payload?.data?.body.substring(0, 10)}`}
                                    />
                                ),
                                duration: 3000,
                                isClosable: true,
                            })
                        )
                    case 'Leave Approval Declined':
                        return (
                            toast({
                                position: 'top-right',
                                render: () => (
                                    <JToast
                                        type={'chat'}
                                        message={`${payload?.data?.title}: ${payload?.data?.body.substring(0, 10)}`}
                                    />
                                ),
                                duration: 3000,
                                isClosable: true,
                            })
                        )
                    case 'Leave Approval Changed':
                        return (
                            toast({
                                position: 'top-right',
                                render: () => (
                                    <JToast
                                        type={'chat'}
                                        message={`${payload?.data?.title}: ${payload?.data?.body.substring(0, 10)}`}
                                    />
                                ),
                                duration: 3000,
                                isClosable: true,
                            })
                        )
                    case 'DELETE_SO_PRODUCT_COMPLETE':
                        localStorage.setItem('progress-delete-product', 'false') 
                        return (
                            toast({
                                position: 'top-right',
                                render: () => (
                                    <JToast
                                        type={'success'}
                                        message={`Delete Product Order Complete`}
                                    />
                                ),
                                duration: 3000,
                                isClosable: true,
                            })
                        )
                    case "UPLOAD_SO_PRODUCT_COMPLETE":
                        return
                    default:
                        break
                }
                resolve(payload)
            });
        });
    }, [])

    useEffect(() => {
        const handler = async () => {
            if (document.visibilityState === 'visible') await handleIncomingChatNotification()
        }

        document.addEventListener('visibilitychange', handler)
        return () => {
            document.removeEventListener('visibilitychange', handler)
        }
    }, [chatId])

    useEffect(() => {
        if (themeLogo === teamV2?.detail?.profile_globalconfig?.logo_cms) return
        setThemeLogo(teamV2?.detail?.profile_globalconfig?.logo_cms)
    }, [teamV2?.detail])

    return (
        <Flex
            as={motion.div}
            className={style.header}
            transition="0.3s"
        >
            {!isBlacklistPage && <span className={`material-symbols-outlined ${style.hamburger}`} onClick={toggleMobileSidebar}>menu</span>}
            <Image
                onClick={() => navigate(urlRouter.home)}
                src={themeLogo ?? lOGO_JARIVIS}
                className={`${style.header_logo} ${style.header_logo_name}`}
            />
            <Image
                onClick={() => navigate(urlRouter.home)}
                src={themeLogo ?? LOGO_JARIVIS_ONLY}
                className={`${style.header_logo} ${style.header_logo_only}`}
            />

            <Flex gap="24px">
                <IconButton
                    aria-label="notification"
                    backgroundColor={'surfacePlus1'}
                    icon={colorMode === 'light' ? <JGoogleIcon fontSize={'20px'} name={'dark_mode'} /> : <JGoogleIcon fontSize={'20px'} name={'light_mode'} />}
                    onClick={toggleTheme}
                    borderRadius={'12px'}
                />
                {!isBlacklistPage && <NotificationButton />}
                {!isBlacklistPage && <ChatButton btnRefChatList={btnRefChatList} onOpenChatList={onOpenChatList} chats={chats} />}
                <UserButton />
            </Flex>

            <ChatListDrawer
                isOpen={isOpenChatList}
                onClose={onCloseChatList}
                chats={chats?.data}
                onClick={handleClickChatItem}
                setChatId={setChatId}
                _ref={btnRefChatList}
                _message_ref={btnRefMessageList}
            />

            <MessageListDrawer
                chatId={chatId}
                taskId={taskId}
                isOpen={isOpenMessageList}
                onClose={handleExitChatRoom}
                _ref={btnRefMessageList}
            />
        </Flex>
    );
};

export default Header