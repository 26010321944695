import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Outlet } from 'react-router-dom'
import { Flex, Heading, Image, Spinner, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { LICENSE_EXPIRED, NO_ACCESS_LOCK } from '../assets/illustrations'
import { teamSliceV2 } from '../redux'
import { useDispatch } from 'react-redux'

interface AccessRouteProps {
  access: number | string
}

const AccessRoute: React.FC<AccessRouteProps> = (props: AccessRouteProps) => {
  const { access } = props

  const teamV2: any = useSelector((state: any) => state?.teamV2)
  const dispatch = useDispatch<any>()
  const { t } = useTranslation()
  const { data, isLoading } = useSelector((state: any) => state.menuPrivilage)

  let accessGranted = data && data.find((x: any) => x?.id == access) !== undefined

  useEffect(() => {
    !teamV2?.detail?.id && dispatch(teamSliceV2?.getTeamDetailAction())
  }, [])

  if (isLoading || teamV2?.isLoadingDetail) return <Flex justifyContent='center' alignItems='center' direction='column' width='100%'><Spinner /></Flex>

  if (!teamV2?.detail?.id)
    return (
      <Flex justifyContent='center' alignItems='center' direction='column' width='100%'>
        <Image src={LICENSE_EXPIRED} height='287' width='287' />
        <Heading textAlign='center' size='chakra_label_large' marginTop='50px' fontSize='32px'>{t('LicenseExpiredTitle')}</Heading>
        <Heading textAlign='center' size='chakra_label_large' marginTop='20px'>{t('LicenseExpiredSubitle')}</Heading>
      </Flex>
    )

  if (accessGranted === false)
    return (
      <Flex justifyContent='center' alignItems='center' direction='column' width='100%'>
        <Image src={NO_ACCESS_LOCK} height='258px' width='258px' />
        <Heading textAlign='center' size='chakra_label_large' marginTop='50px' fontSize='32px'>{t('AccessMenuDeniedTitle')}</Heading>
        <Heading textAlign='center' size='chakra_label_large' marginTop='20px'>{t('AccessMenuDeniedSubtitle')}</Heading>
      </Flex>
    )

  return <Outlet />
}

export default AccessRoute
