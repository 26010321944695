import { Dispatch, SetStateAction, useEffect } from "react";
import { useMutation } from "react-query";
import { useDebounce } from "use-debounce";
import { InvitationMemberList } from "../../../services/member/member";

export const useInvitationList = (
    setConfig: Dispatch<
        SetStateAction<{
            search: string;
            show: number;
            page: number;
        }>
    >,
    config: { search: string; show: number; page: number }
) => {

    const mutation = useMutation(InvitationMemberList);

    useEffect(() => {
        mutation.mutate(config);
    }, [config]);

    return {
        mutate: mutation.mutate,
        data: mutation.data,
        isLoading: mutation.isLoading,
        error: mutation.error,
    };
};
