import React, { useEffect, useState } from 'react';
import { Button, Heading, Box } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { urlRouter } from '../../../router';
import { useMutation } from 'react-query';
import { useDebounce } from 'use-debounce';
import { InvitationMemberList } from '../../../services/member/member';
import { useInvitationList } from '../hook/useInvitationList.hook';

const ButtonInvitationList: React.FC = () => {
    const navigate = useNavigate();

    const [config, setConfig] = useState({
        search: '',
        show: 99,
        page: 1,
    });

    const { data } = useInvitationList(setConfig, config);

    return (
        data && data.data.length > 0 ?
            <Button
                onClick={() => navigate(urlRouter.memberInviteList)}
                variant='outline'
                position='relative'
                p='14px 24px'
                border='1px solid var(--colorPrimary)'
                rounded='12px'
            >
                <Heading size='chakra_label_large' color='primary'>Invitation List</Heading>
                <Box
                    position='absolute'
                    top='-5px'
                    right='-5px'
                    bg='errorContainer'
                    w='15px'
                    h='15px'
                    rounded='full'
                >
                    <Heading size='chakra_label_small'>{data?.data.length}</Heading>
                </Box>
            </Button>
            : null)
};

export default ButtonInvitationList;